body {
  padding: 0;
  margin: 0 1em;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 95%);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, 
    Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue ,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* highlight in house colours */
::selection {
  color: hsl(0, 0%, 95%);
  background: hsl(11, 85%, 60%);
}

::-moz-selection {
  color: hsl(0, 0%, 95%);
  background: hsl(11, 85%, 60%);
}