/* The snackbar - position it at the bottom and in the middle of the screen */
.snackbar-container {
  left: 0;
  right: 0;
  width: 100%;
  bottom: 1rem;
  position: fixed;
  text-align: center;
}

.snackbar {
  z-index: 1;
  color: #fff;
  padding: 1rem;
  visibility: hidden;
  border-radius: .5rem;
  background-color: #333;
  display: inline-block;
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

h1, h2 {
  margin: 0.2em 0;
}

h1, h2,
a {
  color: hsl(11, 85%, 60%);
}

header {
  text-align: center;
}

header .glyphs {
  display: grid;
  margin: 2em 0;
  grid-template-columns: repeat(4, auto);
}

header .glyphs .print-only-view,
.read-more .print-only-view {
  color: hsl(11, 85%, 60%);
}

.main-content {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: max-content auto;
}

main {
  display: inline-block;
}

main section {
  margin: 1em;
}

main section .headings {
  display: grid;
  grid-gap: 0.25em;
  grid-template-rows: repeat(2, max-content);
  grid-template-columns: 1fr repeat(2, max-content);
}

main section .headings h3,
main section .headings h4 {
  margin: 0;
}

main section .headings :nth-child(2) {
  grid-row: 2;
  grid-column: 1;
}

main section .headings :nth-child(3) {
  grid-row: 1/3;
  grid-column: 2;
  align-self: center;
} 

main section .headings :nth-child(4),
main section .headings :nth-child(5) {
  text-align: center;
}

main section .read-more {
  text-align: right;
}

main #project-history.hide-content {
  display: none;
}

main #project-history section {
  margin-bottom: 2rem;
}

main section .button {
  color: #fff;
  padding: 1rem;
  cursor: pointer;
  border-radius: .25rem;
  border: 1px solid transparent;
  background-color: hsl(11, 85%, 60%);
}

main section .button:hover,
main section .button:focus {
  background-color: hsl(11, 100%, 50%);
}

main section .button:hover {
  transform: translateY(-1px);
}

main section .button:active {
  transform: translateY(0);
  background-color: hsl(11, 85%, 60%);
}

aside {
  text-align: center;
  display: inline-block;
}

aside h2 {
  text-align: center;
}

aside .location {
  margin: 1em 0;
}

aside .skill-wall {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: 16em;
  grid-template-rows: max-content;
}

aside .skill-wall ul.skills-list {
  margin: 0;
  padding: 0;
  display: inline-block;
  column-gap: 1em;
  -moz-column-gap: 1em;
  -webkit-column-gap: 1em; 
  column-width: auto;
  -moz-column-width: auto;
  -webkit-column-width: auto;
   
 }
 
aside .skill-wall ul.skills-list li {
  margin: 0.1em;
  padding: 0.2em;
  border: 1px solid black;
  display: inline-block;
 }

 aside .skill-wall .skill-description {
  padding: 1em;
}

aside .skill-wall .skill-description h3 {
  margin: 0 auto;
  color: hsl(11, 85%, 60%);
}

.certificate-badge {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.print-only-view.certificate-badge {
  max-width: 15rem;
}

/* icons */
.icon-primary {
  fill: hsl(11, 85%, 60%);
}

.icon-secondary {
  fill: hsl(0, 0%, 20%);
}

.icon {
  width: 24px;
  height: auto;
  vertical-align: sub;
}

.icon.btn {
  padding: 8px;
}

/* responsive media queries */
@media screen and (max-width: 980px), print {
  .main-content {
    display: grid;
    grid-template-rows: max-content auto;
    grid-template-columns: auto;
  }

  .main-content main {
    grid-row: 1;
  }

  .main-content aside {
    grid-row: 2;
  }

  .main-content aside .location {
    display: none;
  }

  .main-content aside .skill-wall {
    grid-template-columns: auto;
  }

  header .glyphs {
    grid-gap: 1rem;
    grid-template-columns: repeat(2, auto);
  }
}

footer {
  width: 100%;
  text-align: center;
}

/* print preview styles */
.print-only-view {
  display: none;
}

@media print {
  .certificate-badge {
    display: none;
  }
  .read-more a .web-text {
    display: none;
  }

  .print-only-view {
    display: inline-block;
  }

  main #project-history.hide-content {
    display: block;
  }

  main section .button {
    display: none;
  }
}

/* fine pointer devices (stops mobile sticky hovers) */
@media (pointer:fine) {
  a:hover {
    color: hsl(11, 100%, 50%);
    text-shadow: hsl(11, 85%, 60%) 0px 0px 35px;
  }

  aside .skill-wall ul.skills-list li:hover {
   cursor: pointer;
   color: hsl(11, 85%, 60%);
  }
}